<template>
  <div>
    <v-row class="mb-1" no-gutters>
      <v-col cols="12" md="12">
        <h3 class="text-center">연체 상태표</h3>
      </v-col>
    </v-row>

    <v-row class="mb-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-simple-table style="border: 1px solid #eeeeee">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="25%" class="text-center">상태이름</th>
                <th width="50%" class="text-center">설명</th>
                <th width="25%" class="text-center">이관 수수료율</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="overdueStatuses.length > 0">
                <tr
                  v-for="overdueStatus in overdueStatuses"
                  :key="overdueStatus.idx"
                  class="clickable-data-row cursor--pointer"
                  :class="
                    selectedOverdueStatus.idx !== null &&
                    selectedOverdueStatus.idx == overdueStatus.idx
                      ? 'selected'
                      : ''
                  "
                  @click="selectOverdueStatus(overdueStatus)"
                >
                  <td
                    class="text-center"
                    :class="'overdue-status__' + (overdueStatus.idx - 1)"
                    style="padding: 23px 0 !important"
                  >
                    <template v-if="overdueStatus.edit">
                      <v-text-field
                        v-model="overdueStatus.name"
                        hide-details
                        dense
                      ></v-text-field>
                    </template>

                    <template v-else>
                      {{ overdueStatus.name }}
                    </template>
                  </td>
                  <td class="text-center">
                    <template v-if="overdueStatus.edit">
                      <v-textarea
                        v-model="overdueStatus.description"
                        hide-details
                        dense
                        rows="2"
                      ></v-textarea>
                    </template>
                    <template v-else>
                      <p class="ma-0 white-space--pre-line">
                        {{ overdueStatus.description }}
                      </p>
                    </template>
                  </td>
                  <td class="text-center">
                    <template v-if="overdueStatus.edit">
                      <v-text-field
                        v-model="overdueStatus.entrustmentFeeRate"
                        type="number"
                        hide-details
                        dense
                      ></v-text-field>
                    </template>

                    <template v-else>
                      {{ overdueStatus.entrustmentFeeRate }}
                    </template>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="3">이곳에 상태정보가 표시됩니다.</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="3" sm="3" md="3">
        <v-btn
          color="#393939"
          class="white--text mb-5"
          center
          depressed
          :loading="getDataLoading"
          :disabled="getDataLoading"
          @click="addRow()"
        >
          상태추가
        </v-btn>
      </v-col>

      <v-col cols="6" sm="6" md="6" class="text-center">
        <v-btn
          color="#393939"
          class="white--text mb-5"
          center
          depressed
          :loading="getDataLoading"
          :disabled="getDataLoading"
          @click="updateOverdueStatus()"
        >
          저장
        </v-btn>
      </v-col>

      <v-col cols="3" sm="3" md="3"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      getDataLoading: false,
      overdueStatuses: [],
      selectedOverdueStatus: { idx: null },
    }
  },
  created: function () {
    this.getOverdueStatuses()
  },
  methods: {
    async getOverdueStatuses() {
      this.getDataLoading = true

      let q = `
        data: getOverdueStatuses {
            idx
            name
            description
            entrustmentFeeRate
          }
        `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        data.forEach((d) => {
          d.edit = false
        })
        this.overdueStatuses = data
      } finally {
        this.getDataLoading = false
      }
    },
    getStatusName(index) {
      if (this.overdueStatuses.length < 1) {
        return
      }
      return this.overdueStatuses[index - 1].name
    },
    addRow() {
      let row = {
        idx: null,
        name: null,
        description: null,
        entrustmentFeeRate: null,
        edit: true,
      }

      this.overdueStatuses.push(row)
    },
    async updateOverdueStatus() {
      let param = this.overdueStatuses.reduce((arr, d) => {
        if (
          d.edit === true &&
          !!d.name &&
          !!d.description &&
          !!d.entrustmentFeeRate
        ) {
          arr.push({
            idx: d.idx,
            name: d.name,
            description: d.description,
            entrustmentFeeRate: d.entrustmentFeeRate,
          })
        }
        return arr
      }, [])

      if (param.length < 1) {
        this.$swal.basic.alert({ text: '변경할 내용이 없습니다.' })
        return
      }

      let yes = await this.$swal.basic.confirm('연체상태표를 업데이트합니다?')

      if (yes.isConfirmed === false) {
        return
      }

      this.getDataLoading = true

      let q = `
          data: modifyOverdueStatus(inputOverdueStatuses: $input) {
            idx
            name
            description
            entrustmentFeeRate
          }
        `

      try {
        let data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: [InputOverdueStatus]!)',
          { input: param }
        )

        if (!!data) {
          data.forEach((d) => {
            d.edit = false
          })
          this.overdueStatuses = data
          this.$swal.basic.success('연체상태가 업데이트되었습니다.')
        }
      } catch (e) {
        this.$swal.basic.error('에러 ㅠㅠㅠ')
      } finally {
        this.getDataLoading = false
      }
    },
    selectOverdueStatus(overdueStatus) {
      console.log('selectOverdueStatus!')

      overdueStatus.edit = true

      this.selectedOverdueStatus = overdueStatus
      this.$emit('selectOverdueStatus', overdueStatus)
    },
  },
}
</script>

<style lang="scss" scoped></style>
